import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { MaterialTableWrapper } from '../common/materialTable'
import { ProgressBar } from '../common/progressBar'
import { TableContainer, ChallengesListHeader, ChallengesListTitle, EtapaItem } from './style'
import { Col } from 'react-bootstrap'
import { DashboardItemBox } from 'main/pages/hub/dashboard/style'
import { Loading } from 'components/loading'
import { Challenge } from 'data/contracts/default'
import { getCurrentStep, getStepProgress } from './behavior'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { NoContent } from 'components/NoContent'
import { format } from 'date-fns'

const buildStep = (challenge: Challenge): JSX.Element => {
  const step = getCurrentStep(challenge)

  return (
    <EtapaItem>
      <span>{step}</span>
      <ProgressBar
        progressColor={'var(--primary)'}
        progressValue={getStepProgress(step)}
        backgroundColor={'var(--primary-light)'}
        height={'5px'}
        width={'50px'}
      />
    </EtapaItem>
  )
}

export const challengesTableConfig = {
  columns: [
    {
      name: 'NOME',
      selector: (challenge: Challenge) => challenge.config.title,
      sortable: true
    },
    {
      name: 'ETAPA',
      selector: (challenge: Challenge) => buildStep(challenge),
      sortable: true
    },
    {
      name: 'PRAZO',
      selector: (challenge: Challenge) => {
        const rawDate = new Date(challenge.config.step.applicationStartDate)
        return challenge.isLaunched ? format(rawDate, 'dd/MM/yyyy') : '-'
      },
      sortable: true,
      sortFunction: (a: Challenge, b: Challenge) =>
        new Date(a.config.step.applicationStartDate).getTime() - new Date(b.config.step.applicationStartDate).getTime()
    },
    {
      name: 'N° DE INSCRIÇÕES',
      selector: (challenge: Challenge) => challenge.statistics.totalIdeas,
      sortable: true
    }
  ]
}

const request: GetFormat = {
  url: '/v1/hub/company/dashboard/challenge'
}

export const ChallengesTable = (): JSX.Element => {
  const labels = {
    tableTitle: trans('Desafios'),
    requestError: trans('Falha ao obter dados de desafios'),
    noChallengesFound: trans('Nenhum desafio encontrado')
  }

  const { data, isError, isLoading, isFetching } = useGet<Challenge[]>(request, 'company-dashboard-challenges')

  const renderContent = (): JSX.Element => {
    if (isFetching || isLoading) {
      return <Loading />
    }
    if (isError) {
      return <NoContent text={labels.requestError} image={NoContentImg} imageWidth="350" imageHeight="182" />
    }
    if (data?.rows.length < 1) {
      return <NoContent text={labels.noChallengesFound} image={NoContentImg} imageWidth="350" imageHeight="182" />
    }
    return <MaterialTableWrapper columns={challengesTableConfig.columns} data={data.rows} />
  }

  return (
    <Col xs={12} md={6} lg={7}>
      <DashboardItemBox>
        <TableContainer>
          <ChallengesListHeader>
            <ChallengesListTitle>{labels.tableTitle}</ChallengesListTitle>
          </ChallengesListHeader>
          {renderContent()}
        </TableContainer>
      </DashboardItemBox>
    </Col>
  )
}

import { Challenge } from 'data/contracts/default'
import { DateTime } from 'luxon'
import { DateUtils } from 'rsuite/esm/utils'
import { format } from 'date-fns'

const currentDate = DateTime.now()

export const challengeSteps = {
  pending: 'Pendente',
  launched: 'Publicado',
  application: 'Inscrições',
  selection: 'Inscrições Encerradas',
  evaluation: 'Avaliações',
  closed: 'Avaliações Encerradas'
}

type ChallengeDatetimes = {
  launch: DateTime
  applicationStart: DateTime
  finalSelection: DateTime
  evaluationStart: DateTime
  closing: DateTime
}

const getChallengeDatetimes = (challenge: Challenge): ChallengeDatetimes => {
  return {
    launch: DateTime.fromISO(challenge.config.step.launchDate).setLocale('pt'),
    applicationStart: DateTime.fromISO(challenge.config.step.applicationStartDate).setLocale('pt'),
    finalSelection: DateTime.fromISO(challenge.config.step.finalSelectionDate).setLocale('pt'),
    evaluationStart: DateTime.fromISO(challenge.config.step.evaluationStartDate).setLocale('pt'),
    closing: DateTime.fromISO(challenge.config.step.closingDate).setLocale('pt')
  }
}

export const getCurrentStep = (challenge: Challenge): string => {
  const dates = getChallengeDatetimes(challenge)
  if (challenge.isLaunched) {
    if (currentDate >= dates.applicationStart && currentDate < dates.finalSelection) {
      return challengeSteps.application
    }
    if (currentDate >= dates.finalSelection && currentDate < dates.evaluationStart) {
      return challengeSteps.selection
    }
    if (currentDate >= dates.evaluationStart && currentDate < dates.closing) {
      return challengeSteps.evaluation
    }
    if (currentDate >= dates.closing) {
      return challengeSteps.closed
    }
    return challengeSteps.launched
  }
  return challengeSteps.pending
}

export const getStepProgress = (step: string): number => {
  const totalSteps = Object.keys(challengeSteps).length
  const currentStepIndex = Object.values(challengeSteps).findIndex((i) => i === step)
  return (100 * currentStepIndex + 1) / totalSteps
}

import React, { useState } from 'react'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosResponse } from 'axios'

import { Filter } from 'components/Filter'
import { Loading } from 'components/loading'
import { showModal } from 'components/popup'
import { showToast } from 'components/toast'
import { BaseButton, InfoArea } from 'iebt-zing-storybook'
import { GetFormat, getProvider, PostFormat, postProvider, useGet } from 'sharedKernel/api'
import { toBase64, triggerFileDownloadOnClientSide } from 'sharedKernel/file/handler'
import { trans } from 'sharedKernel/i18n'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'
import { changeEvaluatorSolutions, resetEvaluatorSolutions, store, TReducers, useSelector } from 'store'
import { AssignModal } from './AssignModal'
import { HubCompanyEvaluatorsAssignTable } from './table'
import { ButtonArea } from './styles'
import { sortSolutions } from './table/behavior'

interface TablesData {
  header: string[]
  rows: string[][]
}

export const HubCompanyEvaluatorsAssign = (): JSX.Element => {
  const labels = {
    name: trans('Atribuir avaliador'),
    infoMsg: trans('Baixe o arquivo modelo abaixo para preenchimento dos avaliadores convidados e em seguida importe-os no botão abaixo.'),
    importTitle: trans('Importar'),
    exportTitle: trans('Exportar'),
    onImportSuccess: trans('Avaliadores importados com sucesso'),
    onImportSomeError: trans('Alguns avaliadores não puderam ser importados'),
    status: trans('Status'),
    solution: trans('Solução'),
    assignEvaluatorSuccess: trans('Atribuições salvas com sucesso'),
    assignEvaluatorFail: trans('Houve um erro ao atribuir avaliador'),
    editAssign: trans('Editar Atribuição'),
    confirmMessage: trans('Deseja realmente alterar as atribuições dos avaliadores selecionados?'),
    modalButton: trans('Salvar'),
    modalCancelButton: trans('Cancelar')
  }

  const evaluatorAssignedSolutions = useSelector((state: TReducers) => state.evaluatorSolutionState)

  const downloadFileRef: React.RefObject<HTMLAnchorElement> = React.createRef()
  const uploadFileRef: React.RefObject<HTMLInputElement> = React.createRef()

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')
  const { challengeId } = useParams()
  const [tablesData, setTablesData] = useState<TablesData>({ header: [], rows: [] })

  const requestEvaluators: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}/evaluators`
  }

  const requestSolutions: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}/solution/status/all`
  }

  const {
    data: evaluatorData,
    isFetching: isEvaluatorFetching,
    refetch: evaluatorRefetch
  } = useGet<any>(requestEvaluators, 'evaluator-get')

  const {
    data: solutionsData,
    isFetching: isSolutionsFetching,
    refetch: solutionsRefetch
  } = useGet<any>(requestSolutions, 'solutions-get')

  const onSuccessUploadAssingFile = (importedEvaluators: any): boolean => {
    let someError = false
    const evaluatorsTableParams = importedEvaluators.map(evaluator => {
      if (evaluator.status === 'error') {
        someError = true
      }
      return [evaluator.evaluatorName, evaluator.solutionTitle, evaluator.status]
    })
    const evaluatorsTableData = { header: [labels.name, labels.solution, labels.status], rows: [...evaluatorsTableParams] }
    setTablesData({ ...evaluatorsTableData })
    return someError
  }

  const onSuccessUploadFile = async (response: AxiosResponse): Promise<void> => {
    let someError = false
    const importResponse = JSON.parse(response.request.response)

    someError = onSuccessUploadAssingFile(importResponse.rows)

    if (someError) {
      showToast('warning', labels.onImportSomeError)
    } else {
      showToast('success', labels.onImportSuccess)
    }
  }

  const { mutate: uploadFile } = postProvider(onSuccessUploadFile)
  const { mutate: downloadFile } = getProvider()

  const processFile = async (file: any): Promise<void> => {
    const fileContent = await toBase64(file)
    const mimetype = file.type
    const fileData = {
      mimetype: mimetype,
      name: btoa(unescape(encodeURIComponent(file.name))),
      content: fileContent
    }
    uploadFile({
      url: `/v1/hub/company/challenge/${challengeId}/evaluators-solutions/import`,
      data: { data: fileData }
    })
  }

  const { mutate: assignEvaluator } = postProvider()

  const sucessfullyAssignEvaluator = (): void => {
    showToast('success', labels.assignEvaluatorSuccess)
    evaluatorRefetch()
    solutionsRefetch()
  }

  const unsucessfullyAssignEvaluator = (): void => {
    showToast('error', labels.assignEvaluatorFail)
  }

  const showEditModal = (evaluator: any, solutionList: string[]): void => {
    showModal('assignModal', {
      title: labels.editAssign,
      popUpMessage: labels.confirmMessage,
      component: <AssignModal
        evaluator={evaluator}
        evaluatorSolutions={solutionList}
        allSolutions={sortSolutions(solutionsData.rows)}
        updateAssignedSolution={updateAssignedSolution}
        initializeAssignedSolutions={initializeAssignedSolutions}
        resetAssignedSolutions={resetAssignedSolutions}
      />,
      buttons: [{ text: labels.modalButton }, { text: labels.modalCancelButton }],
      action: () => { handleSaveAssignments(evaluator.userId, evaluatorAssignedSolutions.evaluatorSolutions) }
    })
  }

  const updateAssignedSolution = (solutionId: string, isAssignSolution: boolean): void => {
    if (isAssignSolution) {
      evaluatorAssignedSolutions.evaluatorSolutions = [...evaluatorAssignedSolutions.evaluatorSolutions, solutionId]
    } else {
      evaluatorAssignedSolutions.evaluatorSolutions = evaluatorAssignedSolutions.evaluatorSolutions.filter((solution) => {
        return solution !== solutionId
      })
    }

    store.dispatch(changeEvaluatorSolutions(evaluatorAssignedSolutions.evaluatorSolutions))
  }

  const initializeAssignedSolutions = (assignedSolutions: string[]): void => {
    evaluatorAssignedSolutions.evaluatorSolutions = [...evaluatorAssignedSolutions.evaluatorSolutions, ...assignedSolutions]
    store.dispatch(changeEvaluatorSolutions(evaluatorAssignedSolutions.evaluatorSolutions))
  }

  const resetAssignedSolutions = (): void => {
    evaluatorAssignedSolutions.evaluatorSolutions = []
    store.dispatch(resetEvaluatorSolutions(evaluatorAssignedSolutions.evaluatorSolutions))
  }

  const handleSaveAssignments = (evaluatorId: string, assignedSolutions: string[]): void => {
    const postRequest: PostFormat = {
      url: `/v1/hub/company/challenge/${challengeId}/evaluator/${evaluatorId}/attach/solutions`,
      data: {
        data: {
          solutions: assignedSolutions
        }
      }
    }

    assignEvaluator(postRequest, {
      onSuccess: () => {
        sucessfullyAssignEvaluator()
      },
      onError: () => {
        unsucessfullyAssignEvaluator()
      }
    })
  }

  const triggerUpload = (): void => {
    uploadFileRef.current!.click()
  }

  const onSearch = (searchTerm: string): void => {
    setCurrentSearchTerm(searchTerm)
  }

  return (
    <>
      <div className="pb-4">
        <InfoArea areaContent={labels.infoMsg} />
      </div>
      <FilterOrderBar>
        <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />
        <ButtonArea>
          <div>
            <BaseButton
              btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'upload']} />
                {labels.importTitle}</span>}
              variant="secondary"
              onClick={() => triggerUpload()} />
          </div>
          <input
            hidden
            ref={uploadFileRef}
            type="file"
            accept='.xlsx'
            onChange={(event) => {
              const file = event.target.files !== null ? event.target.files[0] : null
              if (file !== null) {
                processFile(file)
              }
              event.target.value = ''
            }} />
          <BaseButton
            btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'download']} />
              {labels.exportTitle}</span>}
            onClick={() => {
              downloadFile(
                {
                  url: `v1/hub/company/challenge/${challengeId}/evaluators-ideas/export`,
                  httpConfig: { responseType: 'blob' }
                },
                {
                  onSuccess: (res) => {
                    triggerFileDownloadOnClientSide(res.data, 'zing-modelo-atribuicao-avaliadores.xlsx')
                  },
                  onError: (rej) => {
                    showToast('error', rej?.response?.data?.message)
                  }
                }
              )
            }}
            variant="secondary" />
        </ButtonArea>
      </FilterOrderBar>
      {(isEvaluatorFetching || isSolutionsFetching)
        ? <div className="pt-5"><Loading /></div>
        : <HubCompanyEvaluatorsAssignTable evaluators={evaluatorData} searchTerm={currentSearchTerm} showEditModal={showEditModal} />
      }
    </>
  )
}
